var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"main-block modal modal--light"},[_c('div',{staticClass:"modal__logo-circle"},[_c('img',{attrs:{"src":require("@/assets/img/space-swap-cow.svg")}})]),(!_vm.type)?_c('div',{staticClass:"modal-tabs"},[_c('div',{class:['modal-tabs__tab', { 'modal-tabs__tab--current': _vm.currentAction === 'buy' }],on:{"click":function($event){_vm.currentAction = 'buy'}}},[_vm._v(_vm._s(_vm.$t('index-token.trade-modal.buy')))]),_c('div',{class:['modal-tabs__tab', { 'modal-tabs__tab--current': _vm.currentAction === 'sell' }],on:{"click":function($event){_vm.currentAction = 'sell'}}},[_vm._v(_vm._s(_vm.$t('index-token.trade-modal.sell')))])]):_vm._e(),_c('div',{class:[
      'index-token-trade__content',
       {'index-token-trade__content--reverse': _vm.currentAction === 'sell'}
    ]},[_c('MainInput',{staticClass:"modal__input",attrs:{"label":_vm.currentAction === 'buy' ?
           _vm.$t('index-token.trade-modal.pay') :
            _vm.$t('index-token.trade-modal.receive'),"maxValue":_vm.currentAction === 'buy' ? _vm.balance.toString() : null,"setPercents":[25, 50, 75, 100],"options":_vm.options,"currentOption":_vm.token0.address || null,"decimals":_vm.token0.decimals},on:{"select":_vm.selectAction,"focus":function($event){_vm.input0IsActive = true; _vm.input1IsActive = false}},nativeOn:{"click":function($event){_vm.input0IsActive = true; _vm.input1IsActive = false}},scopedSlots:_vm._u([{key:"controls",fn:function(){return [(_vm.currentAction === 'buy')?_c('MainInputPercent',{attrs:{"values":[25, 50, 75, 100]},on:{"setValue":_vm.setPercent0}}):_vm._e()]},proxy:true}]),model:{value:(_vm.token0.value),callback:function ($$v) {_vm.$set(_vm.token0, "value", $$v)},expression:"token0.value"}}),_c('MainInput',{staticClass:"modal__input",attrs:{"maxValue":_vm.currentAction !== 'buy' ? _vm.balance.toString() : null,"label":_vm.currentAction !== 'buy' ?
           _vm.$t('index-token.trade-modal.pay') :
            _vm.$t('index-token.trade-modal.receive'),"token":_vm.token1.address || null,"decimals":_vm.token1.decimals},on:{"focus":function($event){_vm.input1IsActive = true; _vm.input0IsActive = false}},nativeOn:{"click":function($event){_vm.input1IsActive = true; _vm.input0IsActive = false}},scopedSlots:_vm._u([{key:"controls",fn:function(){return [(_vm.currentAction === 'sell')?_c('MainInputPercent',{attrs:{"values":[25, 50, 75, 100]},on:{"setValue":_vm.setPercent1}}):_vm._e()]},proxy:true}]),model:{value:(_vm.token1.value),callback:function ($$v) {_vm.$set(_vm.token1, "value", $$v)},expression:"token1.value"}})],1),(!_vm.needAllowance)?_c('div',{staticClass:"modal__btns"},[_c('Button',{staticClass:"btn-new",on:{"click":function($event){_vm.showModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('index-token.trade-modal.cancel'))+" ")]),_c('Button',{staticClass:"btn-new",on:{"click":_vm.swap}},[_vm._v(_vm._s(_vm.$t('index-token.trade-modal.swap')))])],1):_c('div',{staticClass:"modal__btns"},[(!this.$BN(_vm.token0.allowance).gt(0) && _vm.currentAction === 'buy')?_c('Button',{staticClass:"btn-new",on:{"click":function($event){return _vm.approveToken(_vm.token0.address)}}},[_vm._v("Approve "+_vm._s(_vm.token0.symbol))]):_vm._e(),(!this.$BN(_vm.token1.allowance).gt(0) && _vm.currentAction === 'sell')?_c('Button',{staticClass:"btn-new",on:{"click":function($event){return _vm.approveToken(_vm.token1.address)}}},[_vm._v("Approve "+_vm._s(_vm.token1.symbol))]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }