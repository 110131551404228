<script>
import Button from './Button';

export default {
  name: 'InputComponent',
  inheritAttrs: false,
  components: {
    Button,
  },
  props: {
    value: {
      default: '',
    },
    max: {
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newInputValue) {
        const filteredInputValue = newInputValue
          .replace(/^0+(?=\d)/, '')
          .replace(/,/, '.')
          .replace(/\./, '#')
          .replace(/\./g, '')
          .replace(/#/, '.')
          .replace(/[^0-9.]+/g, '')
          .replace(/(\d*)\.(\d{1,18})(\d*)/, '$1.$2');
        this.$emit('input', filteredInputValue);
        this.$forceUpdate();
      },
    },
  },
};
</script>

<template>
  <div class="input-container">
    <span class="label" v-if="$slots.label">
      <slot name="label"></slot>
    </span>
    <div :class="['input__wrapper', max ? 'input__wrapper--max' : '']">
      <input :disabled="disabled" v-model="inputValue" @change="(e) => $emit('change', e)" />
      <div class="input-max">
        <span>
          <slot name="input"></slot>
        </span>
        <Button class="btn--round" v-if="max" @click="$emit('click')">
          {{ $t('modals.withdraw-deposit.max') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.input-container {
  margin: 0 auto 4rem auto;
  flex-direction: column;
  display: flex;
  max-width: 62rem;

  .label {
    text-align: right;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    color: $b-grey-font-color;
  }

  .input__wrapper {
    margin-top: 0.8rem;
    background: $input-bg;
    max-width: 62rem;
    box-shadow: $input-shadow;
    border-radius: 4.6rem;
    height: 7rem;
    position: relative;

    body.binance & {
      background: $b-input-bg;
      box-shadow: $b-input-shadow;
    }

    input,
    textarea {
      height: 7rem;
      outline: none;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 2.2rem;
      color: $main-font-color;
      padding: 2.1rem 3rem;
      width: 100%;

      body.binance & {
        color: $b-secondatry-font-color;
      }

      &::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 2.2rem;
        color: #ffbfb4;

        body.binance & {
          color: $b-main-font-color;
        }
      }
    }

    textarea {
      height: 160px !important;
      resize: none;
    }

    &.input__wrapper--max {
      @media (max-width: $breakpoint-sm) {
        height: 14rem;
      }

      input {
        @media (max-width: $breakpoint-sm) {
          width: 100%;
        }
      }
    }

    .input-max {
      position: absolute;
      right: 1rem;
      top: 0.7rem;
      font-weight: 600;
      font-size: 1.5rem;

      @media (max-width: $breakpoint-sm) {
        top: 7rem;
        border-top: 1px dashed;
        left: 2rem;
        right: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 0.5rem;
      }

      .btn.btn--round {
        margin-left: 1rem;
        text-transform: lowercase;
        padding: 2rem 1rem;
        font-weight: normal;
        font-size: 1.8rem;

        @media (max-width: $breakpoint-sm) {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
