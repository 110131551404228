<script>
import IndexTokenRedeemModalUnderlyingItem from '@/components/IndexToken/IndexTokenRedeemModalUnderlyingItem';
import Button from '@/components/Button';
import erc20ABI from '@/abi/erc20.json';
import InputComponent from '@/components/InputComponent';

export default {
  name: 'IndexTokenRedeemModal',
  components: {
    InputComponent,
    Button,
    IndexTokenRedeemModalUnderlyingItem,
  },
  props: {
    tokens: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      requestPending: true,
      redeemValue: 0,
      balanceWei: 0,
      show: false,
    };
  },
  computed: {
    balance() {
      return this.$BN(this.balanceWei).div(this.$BN(10).pow(18));
    },
    redeemValueWei() {
      return this.$BN(this.redeemValue).times(this.$BN(10).pow(18));
    },
  },
  watch: {
    show(val) {
      if (val) this.getBalance();
    },
  },
  created() {
    this.$root.$on('showRedeemModal', this.showModalHandler);
  },
  beforeDestroy() {
    this.$root.$off('showRedeemModal', this.showModalHandler);
  },
  methods: {
    showModalHandler() {
      this.show = true;
    },
    async getBalance() {
      this.requestPending = true;
      const contract = new this.$web3.eth.Contract(erc20ABI, this.$contracts.getAddress('SSI'));
      try {
        this.balanceWei = await contract.methods
          .balanceOf(this.$store.state.User.ethAddress)
          .call();
      } catch (e) {
        console.error('src/components/IndexToken/IndexTokenRedeemModal.vue:69');
        console.error(e);
      }
      this.requestPending = false;
    },
    async redeem() {
      const contract = this.$contracts.getContract('SSIBasicIssuanceModule');
      try {
        const params = [
          this.$contracts.getAddress('SSI'),
          this.redeemValueWei.toString(10),
          this.$store.state.User.ethAddress,
        ];
        const from = this.$store.state.User.ethAddress;
        const gasPrice = await this.$web3.eth.getGasPrice();
        const gas = await contract.methods.redeem(...params).estimateGas({
          from,
        });
        await contract.methods.redeem(...params).send({
          from,
          gas,
          gasPrice,
        });
      } catch (e) {
        console.error('redeem error');
        console.error(e);
      }
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="show"
    @opened="getBalance"
  >
    <div class="modal main-block modal--light">
      <h3 class="modal__ttl">{{ $t('index-token.redeem-modal.title') }}</h3>
      <p class="modal__sub-ttl">{{ $t('index-token.redeem-modal.sub-title') }}</p>
      <p class="modal__sub-ttl">
        {{ $t('index-token.redeem-modal.balance') }} {{ requestPending ? $t('loading') : balance }}
      </p>
        <InputComponent
          :max="true"
          :value="0"
          v-model="redeemValue"
          @click="redeemValue = $BN(balanceWei).div($BN(10).pow(18))"
        >
        </InputComponent>
      <div class="redeem-modal-underlying">
        <div class="redeem-modal-underlying-item">
          <div>
            {{ $t('index-token.redeem-modal.table-param-1') }}
          </div>
          <div>
            {{ $t('index-token.redeem-modal.table-param-2') }}
          </div>
          <div>
            {{ $t('index-token.redeem-modal.table-param-3') }}
          </div>
        </div>
        <IndexTokenRedeemModalUnderlyingItem
          v-for="token in tokens"
          :token="token"
          :key="token.address"
          :redeemValue="redeemValue.toString()"
        />
      </div>
      <div class="modal__btns">
        <Button class="btn-new" @click="show = false">
          {{ $t('index-token.redeem-modal.cancel') }}
        </Button>
        <Button class="btn-new" @click="redeem">
          {{ $t('index-token.redeem-modal.redeem') }}
        </Button>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss">
.redeem-modal-underlying {
  margin: 1rem 0 3rem;

  &-item {
    font-size: 1.4rem;
    font-weight: 600;
  }
}
</style>
