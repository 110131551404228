<script>
export default {
  name: 'IndexTokenUnderlyingItem',
  props: {
    token: {
      type: Object,
      default() {
        return {};
      },
    },
    currentPrice: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      imgLoaded: false,
    };
  },
  computed: {
    pricePerSet() {
      return this.$BN(this.token.price)
        .multipliedBy(this.token.weight)
        .div(this.$BN(10).pow(this.token.decimals));
    },
    priceAllocation() {
      return this.pricePerSet.times(100).div(this.currentPrice);
    },
  },
  mounted() {
    this.$nextTick(() => {
      const { line } = this.$refs;
      line.style.width = `${line.parentElement.offsetWidth
      * this.$BN(this.token.weight).div(this.$BN(10).pow(this.token.decimals))
      }px`;
    });
  },
};
</script>

<template>
  <div class="index-token-underlying-item">
    <div class="index-token-underlying-item__params">
      <div>
        <div class="index-token-underlying-item__icon">
          <span v-if="!imgLoaded">{{ token.symbol }}</span>
          <img
            v-show="imgLoaded"
            @load="imgLoaded = true"
            :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${$web3.utils.toChecksumAddress(token.address)}/logo.png`"
            :alt="token.name + '-logo'"
          >
        </div>
        <div class="index-token-underlying-item__name">{{ token.name }}</div>
        <div class="index-token-underlying-item__symbol">{{ token.symbol }}</div>
      </div>
      <div>{{ this.$BN(token.weight).div($BN(10).pow(token.decimals)) }}</div>
      <div>${{ token.price | amount(2) }}</div>
      <div>{{ priceAllocation | amount(2) }}%</div>
      <div>
        ${{ pricePerSet | amount(2) }}
      </div>
    </div>
    <div
      class="index-token-underlying-item__line"
      ref="line"
      :style="{'background': token.color}"
    ></div>
  </div>
</template>

<style lang="scss">
.index-token-underlying-item {
  font-size: 1.4rem;
  @media screen and (max-width: $breakpoint-md) {
    font-size: 2rem;
  }
  &:not(:last-child) {
    margin-bottom: 2.2rem
  }
  &__params {
    display: flex;
    align-items: center;
    margin-bottom: .6rem;
    &--header {
      font-size: 1.4rem;
      font-weight: 600;
    }
    > div {
      width: 100px;
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    > div:nth-child(1) {
      flex: 2 0 150px;
    }
    @media screen and (max-width: $breakpoint-sm){
      > div {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          display: none;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  &__icon {
    position: relative;
    flex-shrink: 0;
    box-shadow: $pools-select-shadow;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    body.binance & {
      box-shadow: $b-pools-select-shadow;
    }
    img {
      display: block;
      max-height: 80%;
      max-width: 80%;
      border-radius: 50%;
    }
  }
  &__name,
  &__symbol {
    font-weight: bold;
  }
  &__name {
    display: block;
    margin-left: 2rem;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  &__symbol {
    display: none;
    margin-left: 1rem;
    @media screen and (max-width: 900px) {
      display: block;
    }
  }
  &__line {
    height: .6rem;
    width: 100%;
    border-radius: .3rem;
    background: red;
  }
}
</style>
