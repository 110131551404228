<script>
import MainInputSelect from '@/components/Controls/MainInputSelect';
import erc20ABI from '@/abi/erc20.json';

export default {
  name: 'MainInput',
  components: { MainInputSelect },
  props: {
    decimals: {
      type: [String, Number],
      default: 0,
    },
    value: {
      type: [String, Number],
      default: 0,
    },
    errorText: {
      type: String,
      default: '',
    },
    maxValue: {
      type: [String, Number, null],
      default: null,
    },
    label: {
      type: [String, Number],
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    currentOption: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: 0,
      imgLoaded: false,
      showSelect: false,
      tokenSymbol: '',
    };
  },
  computed: {
    tokenUrl() {
      if (this.token === this.$contracts.getAddress('eth')) {
        return 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png';
      }
      if (this.currentOption === this.$contracts.getAddress('eth')) {
        return 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png';
      }
      return '';
    },
    enableSelect() {
      return this.options?.length && this.options.length > 1;
    },
    selectedOption() {
      let selectedOption;
      this.options.forEach((option) => {
        if (option.value === this.currentOption) selectedOption = option;
      });
      return selectedOption;
    },
  },
  watch: {
    currentOption() {
      // get token symbol
      this.getTokenSymbol();
    },
    token() {
      // get token symbol
      this.getTokenSymbol();
    },
    value(val) {
      this.currentValue = val;
    },
    currentValue(val) {
      let newVal = val
        .toString()
        .replace(/,/, '.')
        .replace(/\./, '#')
        .replace(/\./g, '')
        .replace(/#/, '.')
        .replace(/[^0-9.]+/g, '');
      // check if current float fractional part more then decimals
      const newValDecimals = newVal.split('.')[1];
      if (this.decimals && newValDecimals?.length > this.decimals) {
        newVal = this.$BN(newVal)
          .toFixed(Number(this.decimals))
          .toString();
      }
      this.currentValue = newVal;
      if (this.currentValue.toString() === newVal) {
        this.$emit('input', newVal);
      }
    },
  },
  created() {
    this.currentValue = this.value;
    this.getTokenSymbol();
  },
  methods: {
    async getTokenSymbol() {
      if (this.token === this.$contracts.getAddress('eth')) {
        this.tokenSymbol = 'ETH';
        return;
      }
      if (this.currentOption === this.$contracts.getAddress('eth')) {
        this.tokenSymbol = 'ETH';
        return;
      }
      const tokenAddress = this.token || this.currentOption;
      // get token symbol
      if (tokenAddress) {
        const tokenERC20Contract = new this.$web3.eth.Contract(erc20ABI, tokenAddress);
        try {
          this.tokenSymbol = await tokenERC20Contract.methods.symbol().call();
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
</script>

<template>
  <div class="main-input">
    <div class="main-input-header" v-if="label || $BN(maxValue).gte(0)">
      <div class="main-input-header__label" v-if="label">{{ label }}</div>
      <div
        v-if="$BN(maxValue).gte(0)"
        class="main-input-header__max-value"
        @click="currentValue = maxValue"
      >
        {{ inputLabel ? inputLabel : $t("input.balance") }}:&nbsp;&nbsp;
        <span>{{ $BN(maxValue) | amount(4) }}</span>
      </div>
    </div>
    <div
      :class="[
        'main-input-body',
        { 'main-input-body--with-symbol': tokenSymbol },
        { 'main-input-body--with-icon': imgLoaded },
        { 'main-input-body--with-select': options && options.length }
      ]"
    >
      <div
        :class="[
          'main-input-body__currency',
          { 'main-input-body__currency--select': options && options.length }
        ]"
        @click.stop="showSelect = !showSelect"
      >
        <img
          class="main-input-body__icon"
          v-show="imgLoaded"
          @load="imgLoaded = true"
          :src="tokenUrl"
        />
        <span class="main-input-body__symbol" v-if="tokenSymbol">
          {{ tokenSymbol }}
        </span>
        <MainInputSelect
          v-if="enableSelect"
          :options="options"
          :showSelect="showSelect"
          :currentOption="currentOption"
          @select="
            $emit('select', $event);
            showSelect = false;
          "
        />
      </div>
      <input
        type="text"
        v-model="currentValue"
        :disabled="disabled"
        placeholder="0"
        @focus="$emit('focus')"
      />
    </div>
    <div class="main-input-controls">
      <slot name="controls"></slot>
    </div>
  </div>
</template>

<style lang="scss">
.main-input {
  font-size: 1.8em;
  * {
    box-sizing: border-box;
  }
  &-header {
    font-size: 0.7em;
    display: flex;
    &__label,
    &__max-value {
      padding: 0.5em 2em;
    }
    &__label {
      font-size: 1em;
      font-weight: bold;
    }
    &__max-value {
      margin-left: auto;
      text-align: right;
      cursor: pointer;
      span {
        font-weight: bold;
      }
    }
  }
  &-body {
    position: relative;
    &__currency {
      position: absolute;
      right: 0;
      top: 15%;
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1em 0 0;
      &--select {
        cursor: pointer;
      }
    }
    &__symbol {
      font-size: 0.7em;
    }
    &__icon {
      width: 1.5em;
      margin-right: 0.5em;
    }
    input {
      font-size: 1em;
      width: 100%;
      padding: 0.8em 2em;
      background: $input-bg;
      border: none;
      border-radius: 2em;
      outline: transparent;
      box-shadow: $input-shadow;

      body.binance & {
        background: $b-main-item-bg;
        box-shadow: $b-main-item-shadow;
        color: #fff;
      }

      &::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
    &--with-symbol {
      input {
        padding-right: 4em;
      }
    }
    &--with-icon {
      input {
        padding-right: 6rem;
      }
    }
    &--with-select {
      input {
        padding-right: 8rem;
      }
    }
  }
}
</style>
