var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-input"},[(_vm.label || _vm.$BN(_vm.maxValue).gte(0))?_c('div',{staticClass:"main-input-header"},[(_vm.label)?_c('div',{staticClass:"main-input-header__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.$BN(_vm.maxValue).gte(0))?_c('div',{staticClass:"main-input-header__max-value",on:{"click":function($event){_vm.currentValue = _vm.maxValue}}},[_vm._v(" "+_vm._s(_vm.inputLabel ? _vm.inputLabel : _vm.$t("input.balance"))+":   "),_c('span',[_vm._v(_vm._s(_vm._f("amount")(_vm.$BN(_vm.maxValue),4)))])]):_vm._e()]):_vm._e(),_c('div',{class:[
      'main-input-body',
      { 'main-input-body--with-symbol': _vm.tokenSymbol },
      { 'main-input-body--with-icon': _vm.imgLoaded },
      { 'main-input-body--with-select': _vm.options && _vm.options.length }
    ]},[_c('div',{class:[
        'main-input-body__currency',
        { 'main-input-body__currency--select': _vm.options && _vm.options.length }
      ],on:{"click":function($event){$event.stopPropagation();_vm.showSelect = !_vm.showSelect}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgLoaded),expression:"imgLoaded"}],staticClass:"main-input-body__icon",attrs:{"src":_vm.tokenUrl},on:{"load":function($event){_vm.imgLoaded = true}}}),(_vm.tokenSymbol)?_c('span',{staticClass:"main-input-body__symbol"},[_vm._v(" "+_vm._s(_vm.tokenSymbol)+" ")]):_vm._e(),(_vm.enableSelect)?_c('MainInputSelect',{attrs:{"options":_vm.options,"showSelect":_vm.showSelect,"currentOption":_vm.currentOption},on:{"select":function($event){_vm.$emit('select', $event);
          _vm.showSelect = false;}}}):_vm._e()],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],attrs:{"type":"text","disabled":_vm.disabled,"placeholder":"0"},domProps:{"value":(_vm.currentValue)},on:{"focus":function($event){return _vm.$emit('focus')},"input":function($event){if($event.target.composing)return;_vm.currentValue=$event.target.value}}})]),_c('div',{staticClass:"main-input-controls"},[_vm._t("controls")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }