<script>
import uniPairABI from '@/abi/uniPair.json';
import erc20ABI from '@/abi/erc20.json';
import { get } from '@/helpers/api/price.api';
import IndexTokenUnderlyingItem from '@/components/IndexToken/IndexTokenUnderlyingItem';
import IndexTokenRedeemModal from '@/components/IndexToken/IndexTokenRedeemModal';

export default {
  name: 'IndexTokenUnderlyingList',
  components: { IndexTokenRedeemModal, IndexTokenUnderlyingItem },
  props: {
    currentPrice: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      tokens: [],
    };
  },
  created() {
    this.getComponents();
  },
  methods: {
    async getComponents() {
      const { data } = await get(['weth']);
      const priceETH = data.weth.usd;
      const uniFactoryContract = this.$contracts.getContract('uniFactory');
      const indexTokenContract = this.$contracts.getContract('SSI');
      const components = await indexTokenContract.methods.getComponents().call();
      components.map(async (component) => {
        const token = {};
        token.address = component;
        // get token weight
        token.weight = await indexTokenContract
          .methods.getDefaultPositionRealUnit(component).call();
        // get token name, symbol, decimals, bgColor
        const tokenERC20Contract = new this.$web3.eth.Contract(erc20ABI, component);
        try {
          token.symbol = await tokenERC20Contract.methods.symbol().call();
          token.name = await tokenERC20Contract.methods.name().call();
          token.decimals = await tokenERC20Contract.methods.decimals().call();
          switch (token.symbol) {
            case 'PAX':
              token.color = '#005121';
              break;
            case 'USDC':
              token.color = '#2675Ca';
              break;
            case 'DAI':
              token.color = '#F6C945';
              break;
            case 'USDT':
              token.color = '#259D77';
              break;
            case 'TUSD':
              token.color = '#002868';
              break;
            case 'sUSD':
              token.color = '#1E1B30';
              break;
            case 'BUSD':
              token.color = '#E8B30C';
              break;
            default:
              token.color = '#fff';
          }
        } catch (e) {
          console.error('error indexTokenUnderlyingList:70');
          console.error(e);
        }
        // get uniswap pool address
        try {
          token.lpAddress = await uniFactoryContract.methods.getPair(this.$contracts.getAddress('weth'), component).call();
        } catch (e) {
          console.error('error indexTokenUnderlyingList:77');
          console.error(e);
        }
        // get reserves and price in eth
        const uniLPContract = new this.$web3.eth.Contract(uniPairABI, token.lpAddress);
        try {
          const reserves = await uniLPContract.methods.getReserves().call();
          const token0Address = await uniLPContract.methods.token0().call();
          if (token0Address.toLowerCase() !== this.$contracts.getAddress('weth').toLowerCase()) {
            token.price = this.$BN(reserves.reserve1)
              .multipliedBy(priceETH)
              .div(reserves.reserve0)
              .div(this.$BN(10).pow(18 - token.decimals));
          } else {
            token.price = this.$BN(reserves.reserve0)
              .multipliedBy(priceETH)
              .div(reserves.reserve1)
              .div(this.$BN(10).pow(18 - token.decimals));
          }
        } catch (e) {
          console.error('error indexTokenUnderlyingList:89');
          console.error(e);
        }
        this.tokens.push(token);
      });
    },
  },
};
</script>

<template>
  <div class="index-token-underlying-list main-block">
    <IndexTokenRedeemModal
      :tokens="tokens"
    />
    <div class="index-token-underlying-item">
      <div class="index-token-underlying-item__params index-token-underlying-item__params--header">
        <div>{{ $t('index-token.underlying-list.header.token') }}Token</div>
        <div>{{ $t('index-token.underlying-list.header.quantity') }}</div>
        <div>{{ $t('index-token.underlying-list.header.token-price') }}</div>
        <div>{{ $t('index-token.underlying-list.header.current-price-allocation') }}</div>
        <div>{{ $t('index-token.underlying-list.header.total-price-per-set') }}</div>
      </div>
    </div>
    <IndexTokenUnderlyingItem
      v-for="token in tokens"
      :token="token"
      :key="token.address"
      :currentPrice="currentPrice"
    />
  </div>
</template>

<style lang="scss">
.index-token-underlying-list {
  padding: 4rem;
  margin: 4rem 0;
}
</style>
