<script>
export default {
  name: 'MainInputPercent',
  props: {
    values: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      currentValue: undefined,
    };
  },
  methods: {
    handleValue(value) {
      this.$emit('setValue', value);
      this.currentValue = value;
    },
  },
};
</script>

<template>
  <div class="main-input-set-percent">
    <div
      class="main-input-set-percent__percent"
      :class="{ 'main-input-set-percent__percent--active': currentValue === value }"
      v-for="(value, i) in values"
      :key="i"
      @click="handleValue(value)"
    >
      <span>{{ value === 100 ? "max" : `${value}%` }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.main-input-set-percent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.8em;
  &__percent {
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $main-item-bg;
    cursor: pointer;
    box-shadow: $btn-shadow;

    body.binance & {
      color: #0f1929;
      font-size: 2.5rem;
      background: linear-gradient(180deg, #f4d353 0%, #ffc117 100%);
      box-shadow: 2px 2px 20px #090808, 2px 2px 20px rgba(10, 9, 8, 0.2), -4px -4px 10px #5c5a52,
        6px 6px 14px rgba(44, 41, 30, 0.64), inset 2px 2px 6px #f4eab4,
        inset -1px -1px 3px rgba(255, 242, 128, 0.73);
      width: 140px;
      padding: 20px;
      border-radius: 15px;

      &--active {
        background: $b-btn-bg-active;
      }
    }

    &:not(:last-child) {
      margin-right: 1em;
    }

    &:hover {
      box-shadow: $btn-shadow-hover;
    }

    span {
      font-size: 0.6em;
      font-weight: bold;
    }
  }
}
</style>
