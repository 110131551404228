<script>
import MainInputSelectOption from '@/components/Controls/MainInputSelectOption';

export default {
  name: 'MainInputSelect',
  components: { MainInputSelectOption },
  props: {
    showSelect: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    currentOption: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <span class="main-input-select">
    <ul class="main-input-select__options" v-show="showSelect" @click.stop>
      <MainInputSelectOption
        v-for="option in options"
        @click.native="$emit('select', option.value)"
        :option="option"
        :key="option.value"
      />
    </ul>
  </span>
</template>

<style lang="scss">
.main-input {
  &-select {
    width: .8em;
    height: 100%;
    background: url('~@/assets/img/input-select-arrow.svg') center no-repeat;
    background-size: 100% auto;
    margin-left: .6em;
    cursor: pointer;
    position: relative;

    &__options {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 10;
      background: #fff;
      padding: .2em 0;
      margin: 0;
      border-radius: .4em;
      box-shadow: $btn-shadow;
    }

    &__option {
      display: flex;
      padding: .4em 1em;
      align-items: center;
      opacity: .7;

      &:hover {
        opacity: 1;
      }

      &-icon {
        width: 1.5em;
        margin-right: 1rem;
      }

      &-name {
        font-size: .7em;
        margin-left: auto;
      }
    }
  }
}
</style>
