<script>
export default {
  name: 'IndexTokenRedeemModalUnderlyingItem',
  props: {
    token: {
      type: Object,
      default() {
        return {};
      },
    },
    redeemValue: {
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      imgLoaded: false,
    };
  },
  computed: {
    receiveValue() {
      return this.$BN(this.token.weight)
        .times(this.redeemValue)
        .div(this.$BN(10).pow(this.token.decimals));
    },
  },
};
</script>

<template>
  <div class="redeem-modal-underlying-item">
    <div>
      <div class="redeem-modal-underlying-item__icon">
        <span v-if="!imgLoaded">{{ token.symbol }}</span>
        <img
          v-show="imgLoaded"
          @load="imgLoaded = true"
          :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${$web3.utils.toChecksumAddress(token.address)}/logo.png`"
          :alt="token.name + '-logo'"
        >
      </div>
      <div class="redeem-modal-underlying-item__name">{{ token.symbol }}</div>
    </div>
    <div>
      {{ this.$BN(token.weight).div(this.$BN(10).pow(token.decimals)) }}
    </div>
    <div>
      {{ isNaN(receiveValue) ? 0 : receiveValue | amount(4) }}
    </div>
  </div>
</template>

<style lang="scss">
.redeem-modal-underlying-item {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(255,121,121,.2);
  > div {
    display: flex;
    align-items: center;
    flex: 1 1 100px;
    &:first-child {
      flex: 1 1 150px;
    }
    &:last-child {
      color: green;
    }
    &:not(:first-child) {
      justify-content: flex-end;
    }
  }
  &__icon {
    background: #fff;
    position: relative;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .7rem;
    img {
      display: block;
      max-height: 80%;
      max-width: 80%;
      border-radius: 50%;
    }
  }
  &__name {
    font-weight: bold;
    margin-left: 1rem;
  }
}
</style>
