<script>
import Button from '@/components/Button';
import IndexTokenUnderlyingList from '@/components/IndexToken/IndexTokenUnderlyingList';
import Jazzicon from 'vue-jazzicon';
import { get } from '@/helpers/api/price.api';
import uniPairABI from '@/abi/uniPair.json';
import IndexTokenTradeModal from '@/components/IndexToken/IndexTokenTradeModal';

export default {
  name: 'Index',
  components: {
    IndexTokenTradeModal,
    IndexTokenUnderlyingList,
    Button,
    Jazzicon,
  },
  data() {
    return {
      currentPrice: 0,
      USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    };
  },
  watch: {
    '$store.state.User.chainId': function fn() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      // get tether price in usd
      const resp = await get('tether');
      const USDTPriceUSD = resp.data.tether.usd;
      // calculate SSI current price based on Uniswap pool and Tether Coingecko price
      const uniFactoryContract = this.$contracts.getContract('uniFactory');
      const lpAddress = await uniFactoryContract.methods.getPair(this.USDT, this.$contracts.getAddress('SSI')).call();
      const uniLPContract = new this.$web3.eth.Contract(uniPairABI, lpAddress);
      const reserves = await uniLPContract.methods.getReserves().call();
      this.currentPrice = this.$BN(reserves.reserve1)
        .multipliedBy(USDTPriceUSD)
        .multipliedBy(this.$BN(10).pow(12))
        .div(reserves.reserve0)
        .toString(10);
    },
  },
};
</script>

<template>
  <div class="main-wrap main-wrap--narrow page-content index-token">
    <IndexTokenTradeModal/>
    <div class="page-content-header index-token-header">
      <div class="index-token-cap main-block main-block--with-shadow main-block--small-radius">
        <Jazzicon :diameter="50" class="index-token-header__icon"/>
        <span>{{ $t('index-token.token-name') }}</span>
        <div class="index-token-cap__value">
          <span>${{ this.$BN(currentPrice) | amount(2) }}</span>
          <span>{{ $t('index-token.current-price') }}</span>
        </div>
      </div>
      <div class="index-token-header__btns">
        <Button
          class="btn-new index-token__btn index-token__btn--mobile"
          @click="$root.$emit('showTradeModal')"
        >{{ $t('index-token.trade') }}</Button>
        <Button
          class="btn-new index-token__btn index-token__btn--desktop"
          @click="$root.$emit('showTradeModal',  'buy');"
        >{{ $t('index-token.buy') }}</Button>
        <Button
          class="btn-new index-token__btn index-token__btn--desktop"
          @click="$root.$emit('showTradeModal',  'sell');"
        >{{ $t('index-token.sell') }}</Button>
        <Button
          class="btn-new index-token__btn"
          @click="$root.$emit('showRedeemModal',  'sell');"
        >
          {{ $t('index-token.redeem') }}
        </Button>
      </div>
    </div>
    <div class="page-content-body">
      <IndexTokenUnderlyingList
        :currentPrice="currentPrice"
      />
      <div class="text-block">
        <h3>{{ $t('index-token.info.title') }}</h3>
        <p>{{ $t('index-token.info.paragraph-1') }}</p>
        <p>{{ $t('index-token.info.paragraph-2') }}</p>
        <p>{{ $t('index-token.info.paragraph-3') }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.index-token {
  @media screen and (max-width: $breakpoint-sm) {
    padding-top: 2rem;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      justify-content: center;
    }

    &__icon {
      zoom: 1;
      @media screen and (max-width: $breakpoint-lg) {
        zoom: .75;
      }
    }

    &__btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        margin-top: 4rem;
        font-size: .8rem;
        justify-content: space-around;
      }
    }
  }

  &__btn {
    margin-left: 1.5em;
    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 0;
      font-size: 1.8rem;
    }

    &--desktop {
      display: block;
      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @media screen and (max-width: $breakpoint-sm) {
        display: block;
      }
    }
  }

  &-cap {
    padding: 1em 2.7em;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 30em;
    font-size: 1.4rem;
    @media screen and (max-width: $breakpoint-md) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }

    > span {
      margin: 0 auto 0 1.5em;
      @media screen and (max-width: $breakpoint-md) {
        margin-left: 1em;
      }
    }

    &__value {
      text-align: right;

      span {
        display: block;
      }

      span:first-child {
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 1.3em;
      }

      span:last-child {
        color: $grey-font-color;
        line-height: 2em;
      }
    }
  }
}
</style>
