<script>
export default {
  name: 'MainInputSelectOption',
  props: {
    option: {
      type: Object,
      default() {
        return {
          name: '',
          value: '',
        };
      },
    },
  },
  data() {
    return {
      imgLoaded: false,
    };
  },
};
</script>

<template>
  <li
    class="main-input-select__option"
  >
    <img
      v-show="imgLoaded"
      @load="imgLoaded = true"
      class="main-input-select__option-icon"
      :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${$web3.utils.toChecksumAddress(option.value)}/logo.png`"
    >
    <span class="main-input-select__option-name">{{ option.name }}</span>
  </li>
</template>

<style>

</style>
