<script>
import MainInput from '@/components/Controls/MainInput';
import erc20ABI from '@/abi/erc20.json';
import Button from '@/components/Button';
import MainInputPercent from '@/components/Controls/MainInputPercent';

export default {
  name: 'IndexTokenTradeModal',
  components: { MainInputPercent, Button, MainInput },
  data() {
    return {
      showModal: false,
      type: null,
      currentAction: 'buy',
      token0: {
        address: '',
        value: null,
        symbol: '',
        allowance: 0,
        decimals: 0,
      },
      token1: {
        address: '',
        value: null,
        symbol: '',
        allowance: 0,
        decimals: 0,
      },
      input0IsActive: false,
      input1IsActive: false,
      input0LastChangedByUser: false,
      input1LastChangedByUser: false,
      currentPair: null,
      options: [],
      balance: 0,
      uniRouterContract: null,
      uniRouterV2Address: null,
      pairs: null,
    };
  },
  computed: {
    needAllowance() {
      return (
        (this.currentAction === 'buy' && !this.$BN(this.token0.allowance).gt(0))
        || (this.currentAction === 'sell' && !this.$BN(this.token1.allowance).gt(0))
      );
    },
    to() {
      return this.$store.state.User.ethAddress;
    },
    from() {
      return this.$store.state.User.ethAddress;
    },
    token0IsETH() {
      return this.token0.address === this.$contracts.getAddress('eth');
    },
    token1IsEth() {
      return this.token1.address === this.$contracts.getAddress('eth');
    },
  },
  watch: {
    showModal(val) {
      if (val) this.beforeOpen();
    },
    currentPair() {
      this.changeHandler();
    },
    currentAction() {
      this.setInputActivity();
      this.changeHandler();
    },
    'token0.value': async function fn(val) {
      if (this.input0IsActive) {
        this.input0LastChangedByUser = true;
        this.input1LastChangedByUser = false;
        if (this.$BN(val).eq(0) || !val) {
          this.token1.value = 0;
          return;
        }
        if (this.currentAction === 'buy') {
          this.token1.value = await this.getUniswapLPAmountsOut(
            this.token0,
            this.token1,
            val,
          );
        } else {
          this.token1.value = await this.getUniswapLPAmountsIn(
            this.token1,
            this.token0,
            val,
          );
        }
      }
    },
    'token1.value': async function fn(val) {
      if (this.input1IsActive) {
        this.input0LastChangedByUser = false;
        this.input1LastChangedByUser = true;
        if (this.$BN(val).eq(0) || !val) {
          this.token0.value = 0;
          return;
        }
        if (this.currentAction === 'sell') {
          this.token0.value = await this.getUniswapLPAmountsOut(
            this.token1,
            this.token0,
            val,
          );
        } else {
          this.token0.value = await this.getUniswapLPAmountsIn(
            this.token0,
            this.token1,
            val,
          );
        }
      }
    },
  },
  created() {
    this.uniRouterContract = Object.freeze(
      this.$contracts.getContract('uniRouter'),
    );
    this.uniRouterV2Address = this.$contracts.getAddress('uniRouter');
    this.$root.$on('showTradeModal', this.showTradeModalHandler);
  },
  beforeDestroy() {
    this.$root.$off('showTradeModal', this.showTradeModalHandler);
  },
  methods: {
    showTradeModalHandler(type) {
      // set current type
      this.type = type;
      if (this.type) {
        this.currentAction = this.type;
      }
      this.showModal = true;
    },
    async changeHandler() {
      this.token0.value = 0;
      this.token1.value = 0;
      [this.token0.address] = this.currentPair;
      [, this.token1.address] = this.currentPair;
      await this.setPairTokensParams();
      await this.getCurrentTokenBalance();
    },
    /**
     * @param {Object} tokenIn - token IN
     * @param {String} tokenIn.address - token IN address
     * @param {Number} tokenIn.decimals - token IN decimals
     * @param {Object} tokenOut - token OUT
     * @param {String} tokenOut.address - token OUT address
     * @param {Number} tokenOut.decimals - token OUT decimals
     * @param {String} inValue - token IN value
     * @returns {string}
     */
    async getUniswapLPAmountsOut(tokenIn, tokenOut, inValue) {
      const path = this.calculatePath(tokenIn.address, tokenOut.address);
      const value = await this.uniRouterContract.methods.getAmountsOut(
        this.$BN(inValue).times(this.$BN(10).pow(tokenIn.decimals)).toFixed(0).toString(),
        path,
      ).call();
      return this.$BN(value[path.length - 1])
        .div(this.$BN(10).pow(tokenOut.decimals)).toString(10);
    },
    /**
     * @param {Object} tokenIn - token IN
     * @param {String} tokenIn.address - token IN address
     * @param {Number} tokenIn.decimals - token IN decimals
     * @param {Object} tokenOut - token OUT
     * @param {String} tokenOut.address - token OUT address
     * @param {Number} tokenOut.decimals - token OUT decimals
     * @param {String} outValue - token OUT value
     * @returns {string}
     */
    async getUniswapLPAmountsIn(tokenIn, tokenOut, outValue) {
      const path = this.calculatePath(tokenIn.address, tokenOut.address);
      const value = await this.uniRouterContract.methods.getAmountsIn(
        this.$BN(outValue).times(this.$BN(10).pow(tokenOut.decimals)).toFixed(0).toString(),
        path,
      ).call();
      return this.$BN(value[0])
        .div(this.$BN(10).pow(tokenIn.decimals)).toString(10);
    },
    async setPairTokensParams() {
      await this.setTokenParamsById('token0');
      await this.setTokenParamsById('token1');
    },
    /**
     * Use for set token params by token ID
     * @param {String} tokenId - token ID could be 'token0' or 'token1'
     * @returns {Object} params
     * @returns {String} params.symbol
     * @returns {String} params.allowance
     * @returns {String, Number} params.decimals
     */
    async setTokenParamsById(tokenId) {
      if (tokenId !== 'token0' && tokenId !== 'token1') {
        throw Error('tokenId must be "token0" or "token1"');
      }
      if (!this[`${tokenId}IsETH`]) {
        const tokenERC20Contract = new this.$web3.eth.Contract(erc20ABI, this[tokenId].address);
        this[tokenId].symbol = await tokenERC20Contract
          .methods
          .symbol()
          .call();
        this[tokenId].allowance = await tokenERC20Contract
          .methods
          .allowance(this.$store.state.User.ethAddress, this.uniRouterV2Address)
          .call();
        this[tokenId].decimals = await tokenERC20Contract.methods
          .decimals()
          .call();
      } else {
        this[tokenId].symbol = 'ETH';
        this[tokenId].allowance = this.$BN(10).pow(40);
        this[tokenId].decimals = 18;
      }
    },
    async approveToken(address) {
      try {
        await new this.$web3.eth.Contract(erc20ABI, address)
          .methods
          .approve(this.uniRouterV2Address, this.$BN(2).pow(256).minus(1).toString())
          .send({ from: this.$store.state.User.ethAddress });
      } catch (e) {
        console.error('approveTokenError');
        console.error(e);
      }
    },
    /**
     * Calculate Uniswap path.
     * If exact pair exist returns [token0, token1]
     * If not returns [token0, WETH, token1]
     * Checks if one of token is ETH (= '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
     * @param token0Address
     * @param token1Address
     * @returns {string[]|(string|string)[]} - uniswap pair path;
     */
    calculatePath(token0Address, token1Address) {
      let token0 = token0Address;
      let token1 = token1Address;
      // check if some token of pair is ETH
      if (token0 === this.$contracts.getAddress('eth')) {
        token0 = this.$contracts.getAddress('weth');
      }
      if (token1 === this.$contracts.getAddress('eth')) {
        token1 = this.$contracts.getAddress('weth');
      }
      // check for straight path
      const uniFactoryContract = this.$contracts.getContract('uniFactory');
      const pair = uniFactoryContract.methods.getPair(token0, token1).call();
      // check if exist
      if (pair !== this.$contracts.getAddress('zero')) {
        return [token0, token1];
      }
      return [token0, this.$contracts.getAddress('weth'), token1];
    },
    swap() {
      let pair;
      let transactionName;
      if (this.currentAction === 'buy') { // if current action buy SSI
        pair = [this.token0, this.token1];
        if (this.input0LastChangedByUser) { // if user want to swap exact amount of token for SSI
          if (this.token0IsETH) {
            // if exact token is eth
            transactionName = 'swapExactETHForTokens';
          } else {
            transactionName = 'swapExactTokensForTokens';
          }
        } else if (this.token0IsETH) {
          transactionName = 'swapETHForExactTokens';
        } else { transactionName = 'swapTokensForExactTokens'; }
      } else { // if current action sell SSI
        pair = [this.token1, this.token0]; // reverse pair
        if (this.input1LastChangedByUser) { // if user want to swap exact amount of SSI for token
          if (this.token0IsETH) {
            // if token is eth
            transactionName = 'swapExactTokensForETH';
          } else {
            transactionName = 'swapExactTokensForTokens';
          }
        } else if (this.token0IsETH) { // if user want to swap SSI for exact amount of token
          transactionName = 'swapTokensForExactETH';
        } else { transactionName = 'swapTokensForExactTokens'; }
      }
      this.sendSwapByTransactionName(...pair, transactionName);
    },
    /**
     * Uniswap swap
     * @param {Object} tokenIn - token
     * @param {Number} tokenIn.value - token value
     * @param {String} tokenIn.address - token address
     * @param {Number} tokenIn.decimals - token decimals
     * @param {Object} tokenOut - eth
     * @param {Number} tokenOut.value - token eth value
     * @param {String} tokenOut.address - token eth address
     * @param {Number} tokenOut.decimals - token eth decimals
     * @param {String} transactionName - [uniswap transaction name]{@link https://uniswap.org/docs/v2/smart-contracts/router02/}
     * @returns {Promise<void>}
     */
    async sendSwapByTransactionName(tokenIn, tokenOut, transactionName) {
      const { to, from } = this;
      const gasPrice = await this.$web3.eth.getGasPrice();
      const path = this.calculatePath(tokenIn.address, tokenOut.address);
      const params = [
        path,
        to,
        Date.now() + 10 * 60 * 1000,
      ];
      let value;
      let amountOut;
      let amountIn;
      switch (transactionName) {
        case 'swapTokensForExactETH':
          amountOut = this.$contracts.toWei(tokenOut.value).toFixed(0).toString();
          amountIn = this.calculateUniAmountMax(tokenIn);
          params.unshift(amountOut, amountIn);
          break;
        case 'swapETHForExactTokens':
          amountIn = this.calculateUniAmountMax(tokenIn);
          amountOut = this.$contracts.toWei(tokenOut.value).toFixed(0).toString();
          params.unshift(amountOut);
          value = amountIn;
          break;
        case 'swapExactETHForTokens':
          amountIn = this.$contracts.toWei(tokenIn.value)
            .toFixed(0)
            .toString();
          amountOut = this.calculateUniAmountMin(tokenOut);
          params.unshift(amountOut);
          value = amountIn;
          break;
        case 'swapExactTokensForETH':
          amountIn = this.$contracts.toWei(tokenIn.value).toFixed(0).toString();
          amountOut = this.calculateUniAmountMin(tokenOut);
          params.unshift(amountIn, amountOut);
          break;
        case 'swapTokensForExactTokens':
          amountOut = this.$contracts.toWei(tokenOut.value, tokenOut.decimals)
            .toFixed(0)
            .toString();
          amountIn = this.calculateUniAmountMax(tokenIn);
          params.unshift(amountOut, amountIn);
          break;
        case 'swapExactTokensForTokens':
          amountIn = this.$contracts.toWei(tokenIn.value, tokenIn.decimals)
            .toFixed(0)
            .toString();
          amountOut = this.calculateUniAmountMin(tokenOut);
          params.unshift(amountIn, amountOut);
          break;
        default:
          throw Error(`
            TransactionName not presented in list
            Possible values:
              swapTokensForExactETH
              swapETHForExactTokens
              swapExactETHForTokens
              swapExactTokensForETH
              swapTokensForExactTokens
              swapExactTokensForTokens
          `);
      }
      const gas = await this.uniRouterContract.methods[transactionName](...params).estimateGas({
        from,
        value,
      });
      return this.uniRouterContract.methods[transactionName](...params).send({
        from,
        value,
        gas,
        gasPrice,
      });
    },
    /**
     * Calculate minimum amount of tokens for swap
     * @param {Object} token
     * @param {Number} token.value - amount in decimals
     * @param {Number} token.decimals - decimals
     * @returns {string}
     */
    calculateUniAmountMin(token) {
      return this.$contracts.toWei(token.value, token.decimals)
        .times(0.95)
        .toFixed(0)
        .toString();
    },
    /**
     * Calculate maximum amount of tokens for swap
     * @param {Object} token
     * @param {Number} token.value - amount in decimals
     * @param {Number} token.decimals - decimals
     * @returns {string}
     */
    calculateUniAmountMax(token) {
      return this.$contracts.toWei(token.value, token.decimals)
        .times(1.05)
        .toFixed(0)
        .toString();
    },
    async getCurrentTokenBalance() {
      let tokenAddress;
      let tokenDecimals;
      let balanceWei;
      if (this.currentAction === 'buy') {
        tokenAddress = this.token0.address;
        tokenDecimals = this.token0.decimals;
      } else {
        tokenAddress = this.token1.address;
        tokenDecimals = this.token1.decimals;
      }
      if (tokenAddress !== this.$contracts.getAddress('eth')) {
        const tokenERC20Contract = new this.$web3.eth.Contract(erc20ABI, tokenAddress);
        try {
          balanceWei = await tokenERC20Contract.methods
            .balanceOf(this.$store.state.User.ethAddress)
            .call();
        } catch (e) {
          console.error(e);
        }
      } else {
        balanceWei = await this.$web3.eth.getBalance(this.$store.state.User.ethAddress);
      }
      this.balance = this.$contracts.fromWei(balanceWei, tokenDecimals);
    },
    selectAction(event) {
      this.pairs.forEach((pair) => {
        if (pair[0] === event) this.currentPair = pair;
      });
    },
    formOptionsForSelect() {
      this.pairs.map(async (pair) => {
        const tokenAddress = pair[0];
        // get token symbol
        if (pair[0] !== this.$contracts.getAddress('eth')) {
          const tokenERC20Contract = new this.$web3.eth.Contract(erc20ABI, tokenAddress);
          const option = {};
          try {
            option.name = await tokenERC20Contract.methods.symbol().call();
            option.value = tokenAddress;
          } catch (e) {
            console.error(e);
          }
          this.options.push(option);
        } else {
          this.options.push({
            name: 'ETH',
            value: tokenAddress,
          });
        }
      });
    },
    setPercent0(percent) {
      this.token0.value = this.$BN(this.balance).times(percent).div(100).toString(10);
    },
    setPercent1(percent) {
      this.token1.value = this.$BN(this.balance).times(percent).div(100).toString(10);
    },
    setInputActivity() {
      if (this.currentAction === 'buy') {
        this.input0IsActive = true;
        this.input1IsActive = false;
      } else {
        this.input0IsActive = false;
        this.input1IsActive = true;
      }
    },
    beforeOpen() {
      this.pairs = this.$contracts.getAddress('SSIPairs');
      // clear options
      this.options = [];
      // set current pair
      [this.currentPair] = this.pairs;
      this.setInputActivity();
      // form options
      this.formOptionsForSelect();
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="showModal"
  >
    <div class="main-block modal modal--light">
      <div class="modal__logo-circle">
        <img src="@/assets/img/space-swap-cow.svg">
      </div>
      <div class="modal-tabs" v-if="!type">
        <div
          :class="['modal-tabs__tab', { 'modal-tabs__tab--current': currentAction === 'buy' }]"
          @click="currentAction = 'buy'"
        >{{ $t('index-token.trade-modal.buy') }}</div>
        <div
          :class="['modal-tabs__tab', { 'modal-tabs__tab--current': currentAction === 'sell' }]"
          @click="currentAction = 'sell'"
        >{{ $t('index-token.trade-modal.sell') }}</div>
      </div>
      <div :class="[
        'index-token-trade__content',
         {'index-token-trade__content--reverse': currentAction === 'sell'}
      ]">
        <MainInput
          class="modal__input"
          v-model="token0.value"
          :label="
            currentAction === 'buy' ?
             $t('index-token.trade-modal.pay') :
              $t('index-token.trade-modal.receive')
          "
          :maxValue="currentAction === 'buy' ? balance.toString() : null"
          :setPercents="[25, 50, 75, 100]"
          :options="options"
          :currentOption="token0.address || null"
          :decimals="token0.decimals"
          @select="selectAction"
          @focus="input0IsActive = true; input1IsActive = false"
          @click.native="input0IsActive = true; input1IsActive = false"
        >
          <template v-slot:controls>
            <MainInputPercent
              v-if="currentAction === 'buy'"
              :values="[25, 50, 75, 100]"
              @setValue="setPercent0"
            />
          </template>
        </MainInput>
        <MainInput
          class="modal__input"
          v-model="token1.value"
          :maxValue="currentAction !== 'buy' ? balance.toString() : null"
          :label="
            currentAction !== 'buy' ?
             $t('index-token.trade-modal.pay') :
              $t('index-token.trade-modal.receive')
          "
          :token="token1.address || null"
          :decimals="token1.decimals"
          @focus="input1IsActive = true; input0IsActive = false"
          @click.native="input1IsActive = true; input0IsActive = false"
        >
          <template v-slot:controls>
            <MainInputPercent
              v-if="currentAction === 'sell'"
              :values="[25, 50, 75, 100]"
              @setValue="setPercent1"
            />
          </template>
        </MainInput>
      </div>
      <div
        class="modal__btns"
        v-if="!needAllowance"
      >
        <Button class="btn-new" @click="showModal = false">
          {{ $t('index-token.trade-modal.cancel') }}
        </Button>
        <Button class="btn-new" @click="swap">{{ $t('index-token.trade-modal.swap') }}</Button>
      </div>
      <div class="modal__btns" v-else>
        <Button
          class="btn-new"
          v-if="!this.$BN(token0.allowance).gt(0) && currentAction === 'buy'"
          @click="approveToken(token0.address)">Approve {{ token0.symbol }}</Button>
        <Button
          class="btn-new"
          v-if="!this.$BN(token1.allowance).gt(0) && currentAction === 'sell'"
          @click="approveToken(token1.address)">Approve {{ token1.symbol }}</Button>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss">
.index-token-trade__content {
  display: flex;
  flex-direction: column;
  &--reverse {
    flex-direction: column-reverse;
 }

 .main-input-header__max-value {
   font-size: 1.4rem;
   font-weight: 600;
 }
  .main-input-body__symbol {
    font-weight: 600;
  }
}
</style>
